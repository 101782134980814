import { isSemanticSearchCalledAtom } from '@components/listings-search-bar/atoms';
import { H3 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { FC } from 'react';
import Typeaheads from '../../../../SearchWidget/Typeaheads/CategoryLocationTypeaheads';
import Styles from './styles.scss';

interface SearchByLocationProps {
	title: string;
}

const SearchByLocation: FC<SearchByLocationProps> = ({ title }) => {
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	return (
		<>
			<H3 className={Styles.title}>{title}</H3>
			<Typeaheads isSemanticSearchCalled={isSemanticSearchCalled} />
		</>
	);
};

export default SearchByLocation;
