import { Snackbar, type SnackbarRef } from '@xo-union/tk-component-alerts';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import showAlertMessage from '../../../utils/showAlertMessage';

interface AlertMessageProps {
	membership: Redux.Membership;
	isSaved?: boolean;
}

export interface AlertMessageRef {
	show(
		vendor: Vendor.Raw,
		isFailure?: boolean,
		response?: unknown,
		isSaved?: boolean,
	): void;
}

const AlertMessage = forwardRef<AlertMessageRef, AlertMessageProps>(
	(props, ref) => {
		const alertRef = useRef<SnackbarRef>(null);
		const { membership, isSaved } = props;

		useImperativeHandle(ref, () => ({
			show: showAlertMessage({ alertRef, membership, isSaved }),
		}));

		return <Snackbar ref={alertRef} />;
	},
);

export default AlertMessage;
