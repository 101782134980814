import { Body2 } from '@xo-union/tk-ui-typography';
import React, { type FC, useContext } from 'react';
import { propsContext } from '../../context';

import Styles from './styles.scss';

const VenueBlurb: FC = () => {
	const { dek } = useContext(propsContext);
	return <Body2 className={Styles.container}>{dek?.description}</Body2>;
};

export default VenueBlurb;
