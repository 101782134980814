import { useListingDebugger } from '@feature-flags/hooks/VendorsSearch/use-listing-debugger';
import React, { forwardRef, useContext, useMemo } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import type { VendorCardLargeProps } from '../../types';
import QuickResponderBadge from '../Badges/QuickResponderBadge';
import Content from '../Content';
import RequestPricingButton from '../RequestPricingButton';

const VendorCardBase = forwardRef<HTMLDivElement, VendorCardLargeProps>(
	(_props, ref) => {
		const {
			badges,
			size,
			id,
			quickResponder,
			adTier,
			personalizedScore,
			purchaseStatus,
			vendorTier,
		} = useContext(propsContext);
		const className = useClassName('container');
		const isListingDebuggerEnabled = useListingDebugger();

		const updatedBadges = useMemo(() => {
			return badges;
		}, [badges]);

		return (
			<>
				<section
					ref={ref}
					className={className}
					aria-labelledby={`vendor-id-${id}`}
					data-testid="vendor-card-base"
					{...(isListingDebuggerEnabled && {
						'data-adtier': adTier,
						'data-personalizedscore': personalizedScore,
						'data-purchasestatus': purchaseStatus,
						'data-vendortier': vendorTier,
					})}
				>
					<Content badges={updatedBadges} />
					{size !== 'sm' && <RequestPricingButton />}
				</section>
				{quickResponder && <QuickResponderBadge />}
			</>
		);
	},
);

export default VendorCardBase;
